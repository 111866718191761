import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import React from "react";
import { useMutation } from "react-query";
import styled from "styled-components";
import { toast } from "react-toastify";

const ChangeTemplateStatus = ({
  open = false,
  onClose = () => {},
  handleRefresh = () => {},
  approvedApiUrl = "",
  rejectedApiUrl = "",
  schoolDocId = "",
  title = "Payment Status",
}) => {
  const { mutate: approvedApiUrlMutate } = useMutation(approvedApiUrl, {
    onSuccess: (su) => {
      handleRefresh();
      onClose();
      toast.success(su?.data?.message ?? "Template Approved Successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "Error");
    },
  });
  const { mutate: rejectedApiUrlMutate } = useMutation(rejectedApiUrl, {
    onSuccess: (success) => {
      handleRefresh();
      onClose();
      toast.success(success?.data?.message ?? "Template Rejected Successfully");
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message ?? "Error");
    },
  });

  function changeStatus(x) {
    if (x) {
      approvedApiUrlMutate({
        schoolDocId,
      });
    } else {
      rejectedApiUrlMutate({
        schoolDocId,
      });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
      }}
      PaperProps={{
        sx: { width: 457, borderRadius: "20px" },
      }}
    >
      <DialogWrapper>
        <DialogTitle sx={{ padding: "0 !important" }}>
          <DialogHeader
            title={title}
            handleClose={() => {
              onClose();
            }}
          />
        </DialogTitle>
        <DialogContent sx={{ border: "0" }}>
          <Stack
            direction={"row"}
            sx={{ justifyContent: "center", padding: "25px" }}
          >
            <img
              width="97px"
              src="/account-remove-icon.png"
              alt="acount remove icon"
            />
          </Stack>
          <Stack
            sx={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "22px ",
              }}
            >
              Status : REQUESTED
            </Typography>
            <Typography variant="body1" sx={{ fontSize: "16px" }}>
              Please select the status want to update
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box width="100%">
            <Stack
              direction={"row"}
              spacing={2}
              sx={{
                justifyContent: "space-between",
                px: 5,
                marginBottom: "35px",
              }}
            >
              <Button
                className="payment-active"
                onClick={() => changeStatus(true)}
              >
                Approve
              </Button>
              <Button
                className="payment-inactive"
                onClick={() => changeStatus(false)}
              >
                Reject
              </Button>
            </Stack>
          </Box>
        </DialogActions>
      </DialogWrapper>
    </Dialog>
  );
};

export default ChangeTemplateStatus;

const DialogWrapper = styled.div`
  // Dilog Box Css
  .dialog-button {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid #2789fd;
    box-sizing: border-box;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
  .payment-active {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(41, 219, 91, 0.4);
    background: rgba(41, 219, 91, 0.1);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    color: #00ac30;
  }
  .payment-inactive {
    width: 170px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 10px;
    border: 1px solid rgba(219, 41, 41, 0.4);
    background: rgba(219, 41, 41, 0.1);
    color: #ff0d02;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }
`;
