import Fallback from "app/components/Fallback";
import React from "react";

const CourseManagement = React.lazy(() => import("./pages/CourseManagement"));
export const CourseManagementPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <CourseManagement {...props} />
  </React.Suspense>
);
const ViewCourse = React.lazy(() => import("./pages/ViewCourse"));
export const ViewCoursePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ViewCourse {...props} />
  </React.Suspense>
);
const Content = React.lazy(() => import("./pages/tabs/Content"));
export const ContentPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Content {...props} />
  </React.Suspense>
);
const About = React.lazy(() => import("./pages/tabs/About"));
export const AboutPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <About {...props} />
  </React.Suspense>
);
const Educators = React.lazy(() => import("./pages/tabs/Educators"));
export const EducatorsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Educators {...props} />
  </React.Suspense>
);
const FAQ = React.lazy(() => import("./pages/tabs/FAQ"));
export const FAQPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <FAQ {...props} />
  </React.Suspense>
);
